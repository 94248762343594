<script setup>
import { ref, defineExpose, watch, onMounted } from "vue";

// Define props
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
    default: "Type",
  },
  value: {
    type: String,
    required: true,
    default: "",
  },
  options: {
    type: Array,
    required: true,
    default: [],
  },
  data: {
    type: Object,
  },
  required: {
    type: Boolean,
    default: false,
  }
});

// Reactive value based on prop
const value = ref(props.value);
const selectedOption = ref(null);
const formRefs = ref([]);

// Method to update the reactive value
const updateValue = (event) => {
  value.value = event.target.value;
  selectedOption.value = props.options.find((opt) => opt.title === value.value);
};

// Expose getData method
defineExpose({
  getData: () => {
    const formData = [];

    if (selectedOption.value?.conditionalComponents) {
      selectedOption.value.conditionalComponents.forEach((comp, ind) => {
        const componentProps = formRefs.value[ind]?.getData();
        if (componentProps) {
          formData.push({
            id: comp.id,
            label: comp.label,
            type: comp.type,
            value: componentProps.value,
            options: componentProps.options || comp.options,
          });
        }
      });
    }

    // return { value: value.value, options: formData };
    return { value: value.value, options: formData };
  },
});

const selectDefault = async () => {
  if(props.data)
  {
    if(props.data.componentName == "Short Text" || props.data.componentName == "Long Text")
      value.value = props.data.componentName;
    else if(props.data.componentName == "Multiple Media" || props.data.componentName == "Single Media")
      value.value = props.data.componentName;
  }
}

// watch for the value changes to update the selected option
watch(value, (newValue) => {
  selectedOption.value = props.options.find((opt) => opt.title === newValue);
});

onMounted(async () => {
  await selectDefault();
})
</script>

<template>
  <div class="formComponentBody">
    <div class="formComponent_Title">{{ props.title }}</div>
    <div class="formRadioButtonGroup">
      <label
        v-for="option in props.options"
        :key="option.title"
        :class="{ formRadioButtonBody: true, active: value === option.title }"
      >
        <input
          type="radio"
          name="radio"
          :id="props.id"
          :value="option.title"
          :checked="value === option.title"
          v-model="value"
          class="radio-button-component"
          @input="updateValue"
          :required="props.required"
        />
        <div class="textContainer">
          <p class="headingText">{{ option.title }}</p>
          <p class="descriptionText">{{ option.description }}</p>
        </div>
      </label>
    </div>

    <!-- conditional components rendering -->
    <div v-if="selectedOption && selectedOption.conditionalComponents">
      <div
        v-for="(component, ind) in selectedOption.conditionalComponents"
        :key="component.id"
      >
        <component
          :is="component.component"
          v-bind="component"
          :ref="(el) => (formRefs[ind] = el)"
        />
      </div>
    </div>
  </div>
</template>
