// store/contentBuilderStore.js
import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate';

const contentBuilderStore = createStore({
  state: {
    items: [], // Array to hold your data
  },
  mutations: {
    ADD_ITEM(state, item) {
      state.items.push(item);
    },
    UPDATE_ITEM(state, { index, newItem }) {
      if (index >= 0 && index < state.items.length) {
        // Create a new array to force reactivity
        const updatedItems = [...state.items];
        updatedItems.splice(index, 1, newItem);
        state.items = updatedItems;
      }
    },
    DELETE_ITEM(state, index) {
      if (index >= 0 && index < state.items.length) {
        state.items.splice(index, 1);
      }
    },
    CLEAR_ITEMS(state) {
      state.items = [];
    },
  },
  actions: {
    addItem({ commit }, item) {
      commit("ADD_ITEM", item);
    },
    updateItem({ commit }, payload) {
      commit("UPDATE_ITEM", payload);
    },
    deleteItem({ commit }, index) {
      commit("DELETE_ITEM", index);
    },
    clearItems({ commit }) {
      commit("CLEAR_ITEMS");
    },
  },
  getters: {
    items: (state) => state.items,
    itemCount: (state) => state.items.length,
  },
  plugins: [createPersistedState()]
});

export default contentBuilderStore;
