<script setup>
import { defineProps, defineEmits, onMounted } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: undefined,
  },
  label: {
    type: String,
    default: "Select an option",
  },
  required: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["update:modelValue"]);
onMounted(() => {
  const optionsArray = Array.from(props.options);
  //   console.log("optionsarray", optionsArray); // ['1', '2', '3', '4']
});
</script>

<template>
  <div class="select-preview">
    <label :for="label"
      >{{ label }}
      <span class="required" v-if="required">(Required)</span>
    </label>
    <select
      :id="label"
      :value="modelValue"
      @change="(event) => emit('update:modelValue', event.target.value)"
      :required="required"
    >
      <option value="" disabled>Select an option</option>
      <option v-for="option in options" :key="option" :value="option">
        {{ option }}
      </option>
    </select>
  </div>
</template>


<style lang="scss" scoped>
.select-preview {
    width: 18vw;
}
</style>
