import api_request from "../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class ContentTypeBuilder {
  api_request = new api_request("/api/service");

  async getCollectionItems(slug) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "getCollectionItems";
      magicJson.data = { slug };
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async createCollectionItem(data) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "createCollectionItem";
      magicJson.data = data;
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async updateCollectionItem(data) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "updateCollectionItem";
      magicJson.data = data;
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }
}

export default ContentTypeBuilder;
