<script setup>
import { ref, defineExpose, onMounted } from "vue";

// Define props
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
    default: "Name",
  },
  type: {
    type: String,
    default: "text",
  },
  value: {
    type: String,
    default: "",
  },
  width: {
    type: String,
  },
  placeholder: {
    type: String,
    default: "",
  },
  rule: {
    type: String,
    default: "",
  },
  data: {
    type: Object,
    default: {}
  },
  required: {
    type: Boolean,
    default: false,
  }

})
  
// Reactive value based on prop
const value = ref(props.data.label || "");

// Method to update the reactive value
const updateValue = (event) => {
  value.value = event.target.value;
};

function resolveValue() {
  if(props.data)
  {
    if(props.label == "Name")
      value.value = props.data.label;

    if(props.label == "Default Value")
      value.value = props.data.defaultValue;

    if(props.label == "RegExp Pattern")
      value.value = props.data.regExp;
  }
}

// Expose getData method
defineExpose({
  getData: () => {
    return { value: value.value };
  },
});

onMounted(() => {
  resolveValue();
  console.log(props.label)
})
</script>

<template>
  <div class="formComponentBody">
    <div class="formComponent_Title">{{ props.label }}</div>
    <div class="formInputFieldBody">
      <input
        :style="{ width: props.width }"
        class="formInput"
        :placeholder="props.placeholder"
        :value="value"
        :type="props.type"
        @input="updateValue"
        :required="props.required"
      />
    </div>

    <p class="rule" v-if="props.rule">
      {{ props.rule }}
    </p>
  </div>
</template>
