<template>
  <div class="bar-chart">
    <apexchart :options="chartOptions" :series="data" :height="height" :stroke="stroke"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "BarChart",
  props: {
    height: {
      type: Number,
      default: 100,
    },
    stroke: {
      type: Number,
      default: 3,
    },
    data: {
      type: Array,
      required: true,
    },
    country: {
      type: Array,
      required: true,
    },
    customOptions: {
      type: Object,
      default: () => ({}), // Default value is an empty object
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "bar",
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          categories: [...this.country],
          labels: {
            show: true,
            rotate: -90,
            style: {
              fontSize: "10px",
            },
          },
        },
        yaxis: {
          show: true,
        },
        dataLabels: {
          enabled: true,
          offsetY: -15,
          style: {
            fontSize: "10px",
            colors: ["#ff5100"],
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top",
            },
          },
        },
        title: {
          text: "Visitors By Country",
          align: "left",
          offsetX: 2,
          offsetY: -2,
          style: {
            fontSize: "16px",
            fontWeight: "bold",
            color: "#0146f4",
          },
        },
      };
    }

  },
};

</script>

<style scoped>
.bar-chart {
  margin-top: 10px;
  margin-right: 8px;
}

::v-deep .apexcharts-toolbar {
  top: -2px !important;
  /* Adjust 'top' as needed and use '!important' to override existing styles */
}
</style>
