<script setup>
import { defineProps, defineEmits, onMounted} from "vue";

const props = defineProps({
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    type: [String, Boolean, Array], // Support for checkbox
    default: undefined,
  },
  label: {
    type: String,
    default: "Label comes here",
  },
  required: {
    type: Boolean,
    default: false,
  },
  minLength: {
    type: Number,
    default: null,
  },
  maxLength: {
    type: Number,
    default: null,
  },
  placeholder: {
    type: String,
    default: "Enter here...",
  },
  customComponent: {
    type: Object, // Expect a component
    default: null,
  },
  pattern: {
    type: String,
    default: null,
  },
  multiple2: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  console.log("multiple2:", props.multiple2);
});

const emit = defineEmits(["update:modelValue"]);
</script>

<template>
  <div class="field-preview">
    <label :for="label">{{ label }}
      <span class="required" v-if="required">(Required)</span></label>

    <component v-if="customComponent" :is="customComponent" :id="label" :value="modelValue" :placeholder="placeholder"
      :required="required" :minlength="minLength" :maxlength="maxLength" :pattern="pattern"
      @input="(event) => emit('update:modelValue', event.target.value)" />

    <input v-else-if="type === 'file' && multiple2" :id="label" :type="type" :placeholder="placeholder"
      :required="required" :minlength="minLength" :maxlength="maxLength" :pattern="pattern"
      @input="(event) => emit('update:modelValue', event.target.files)" multiple
      />

      <input v-else-if="type === 'file' && !multiple2" :id="label" :type="type" :placeholder="placeholder"
      :required="required" :minlength="minLength" :maxlength="maxLength" :pattern="pattern"
      @input="(event) => emit('update:modelValue', event.target.files)" 
      />

    <input v-else-if="type !== 'textarea'" :id="label" :type="type" :value="modelValue" :placeholder="placeholder"
      :required="required" :minlength="minLength" :maxlength="maxLength" :pattern="pattern"
      @input="(event) => emit('update:modelValue', event.target.value)"
      v-bind="type === 'checkbox' ? { checked: modelValue } : {}" />

    <textarea v-else :id="label" :value="modelValue" :placeholder="placeholder" :required="required"
      :minlength="minLength" :maxlength="maxLength" :pattern="pattern"
      @input="(event) => emit('update:modelValue', event.target.value)"></textarea>

    <small class="char-info">
      <span v-if="minLength"> Minimum {{ minLength }} characters</span>
      <span v-if="maxLength"> Maximum {{ maxLength }} characters</span>
    </small>
  </div>
</template>

<style lang="scss" scoped>
.field-preview {
  width: 98%;
  display: flex;
  flex-direction: column;
  gap: 3px;

  label {
    font-weight: bold;

    .required {
      color: #606060;
      font-weight: 300;
      font-size: smaller;
      margin-left: 5px;
    }
  }

  input,
  textarea {
    width: auto !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    color: black;

    padding: 10px;
  }

  input::placeholder,
  textarea::placeholder {
    color: #999;
  }

  .char-info {
    font-size: 12px;
    color: #666;
    display: flex;
    justify-content: space-between;
  }
}
</style>
